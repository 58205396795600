import logoImg from "../img/logo.png";
import background from "../img/hero_bg.jpg";

export default function Hero() {
  return (
    <div
      id="hero"
      className="hero route bg-image"
      style={{ color: "white", backgroundImage: `url('${background}')` }}
    >
      <div className="overlay-itro"></div>
      <div className="hero-content display-table">
        <div className="table-cell">
          <div className="container">
            <h1 className="hero-title mb-4">
              <img src={logoImg} width={130} alt="logo" />
            </h1>
            {/* <p className="hero-subtitle"><span className="typed" data-typed-items="Y LOS DESARRAIGADOS">Y LOS DESARRAIGADOS</span></p> */}
            {/* <p className="pt-3"><a className="btn btn-primary btn js-scroll px-4" href="#about" role="button">Learn More</a></p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
