import mapa from "../img/mapa.png";
import overlayBg from "../img/overlay-bg.jpg";

export default function Contact() {
  return (
    <section
      id="contact"
      className="paralax-mf footer-paralax bg-image sect-mt4 route"
      style={{ backgroundImage: `url('${overlayBg}')` }}
    >
      <div className="overlay-mf"></div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="contact-mf">
              <div id="contact" className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                    <div className="title-box-2">
                      <h5 className="title-left">Contacto</h5>
                    </div>
                    <div>
                      <form
                        action="forms/contact.php"
                        method="post"
                        className="php-email-form"
                      >
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="name"
                                placeholder="Tu Nombre"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Tu Email"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="subject"
                                id="subject"
                                placeholder="Asunto"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="message"
                                rows="5"
                                placeholder="Mensaje"
                                required
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12 text-center my-3">
                            <div className="loading">Cargando</div>
                            <div className="error-message"></div>
                            <div className="sent-message">
                              Mensaje enviado correctamente. Gracias!
                            </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <button
                              type="submit"
                              className="button button-a button-big button-rouded"
                            >
                              Enviar Mensaje
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="title-box-2 pt-4 pt-md-0">
                      <h5 className="title-left">Adonde estamos?</h5>
                    </div>
                    <div className="more-info">
                      <p className="lead">
                        <img src={mapa} style={{ maxWidth: "100%" }} alt="" />
                      </p>
                      <ul className="list-ico">
                        <li>
                          <span className="bi bi-geo-alt"></span> BARCELONA,
                          ESPAÑA
                        </li>
                        <li>
                          <span className="bi bi-envelope"></span>
                          contacto@daisysunset.es
                        </li>
                      </ul>
                    </div>
                    {/* <div className="socials">
                          <ul>
                            <li><a href="#"><span className="ico-circle"><i className="bi bi-facebook"></i></span></a></li>
                            <li><a href="#"><span className="ico-circle"><i className="bi bi-instagram"></i></span></a></li>
                            <li><a href="#"><span className="ico-circle"><i className="bi bi-twitter"></i></span></a></li>
                            <li><a href="#"><span className="ico-circle"><i className="bi bi-linkedin"></i></span></a></li>
                          </ul>
                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
