import { Col, Container, Row } from "react-bootstrap";
import hombreMangasBlack from "../img/merch/hombre-mangas-black.png";
import hombreMangasWhite from "../img/merch/hombre-mangas-white.png";
import hombreTirantesBlack from "../img/merch/hombre-tirantes.png";
import damaBlackLogoSmall from "../img/merch/dama-black-logo-small.png";
import damaBlack from "../img/merch/dama-black.png";
import damaWhite from "../img/merch/dama-white.png";
import damaWhiteSmall from "../img/merch/dama-white-small.png";
import gorraBlack from "../img/merch/gorra-black.png";

export default function Merch() {
  return (
    <section id="merch" className="about-mf sect-pt4 route">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="title-box text-center">
              <h3 className="title-a">Merchandising</h3>
              <p className="subtitle-a">
                Eres nuestro fan #1, consigue camisetas, gorras y más aquí.
              </p>
              <div className="line-mf"></div>
            </div>

            <div
              style={{
                marginBottom: "3rem",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Row className="merch-list">
                <Col sm={4}>
                  <a
                    href="https://www.positivos.com/personalizar?product_id=c57ec46b3c407af84d239153d4afaa0d%3A17159325641254114643%3A97%3A000001&parent_id=92705"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={hombreMangasBlack}
                      alt="camiseta de hombre negra con diseño en el pecho"
                    />
                  </a>
                </Col>
                <Col sm={4}>
                  <a
                    href="https://www.positivos.com/personalizar?product_id=c57ec46b3c407af84d239153d4afaa0d%3A1715932729157827795%3A8%3A000000&parent_id=16063"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={hombreTirantesBlack}
                      alt="camiseta de tirantes de hombre negra con diseño en el pecho"
                    />
                  </a>
                </Col>
                <Col sm={4}>
                  <a
                    href="https://www.positivos.com/personalizar?product_id=c57ec46b3c407af84d239153d4afaa0d%3A1715932829246534332%3A99%3A000000&parent_id=16302"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={damaBlackLogoSmall}
                      alt="camiseta de dama negra con diseño en el pecho"
                    />
                  </a>
                </Col>
                <Col sm={4}>
                  <a
                    href="https://www.positivos.com/personalizar?product_id=c57ec46b3c407af84d239153d4afaa0d%3A17159326951599511100%3A74%3A000000&parent_id=42807"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={damaBlack}
                      alt="camiseta de dama negra con diseño en el pecho"
                    />
                  </a>
                </Col>
                <Col sm={4}>
                  <a
                    href="https://www.positivos.com/personalizar?product_id=c57ec46b3c407af84d239153d4afaa0d%3A1715932766534702414%3A13%3A000000&parent_id=5443"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={gorraBlack}
                      alt="gorra negra con diseño en el frente"
                    />
                  </a>
                </Col>
                <Col sm={4}>
                  <a
                    href="https://www.positivos.com/tshirtecommerce/sharing.php/c57ec46b3c407af84d239153d4afaa0d:1715959497156394189:97:FFFFFF:92705"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={hombreMangasWhite}
                      alt="camiseta de hombre blanca con diseño en el pecho"
                    />
                  </a>
                </Col>
                <Col sm={4}>
                  <a
                    href="https://www.positivos.com/tshirtecommerce/sharing.php/c57ec46b3c407af84d239153d4afaa0d:1715959723930815302:74:FFFFFF:42807"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={damaWhite}
                      alt="camiseta de dama blanca con diseño en el pecho"
                    />
                  </a>
                </Col>
                <Col sm={4}>
                  <a
                    href="https://www.positivos.com/tshirtecommerce/sharing.php/c57ec46b3c407af84d239153d4afaa0d:1715960494735843250:99:FFFFFF:16302"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={damaWhiteSmall}
                      alt="camiseta de dama blanca con diseño en el pecho"
                    />
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
