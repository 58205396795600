import { Col, Container, Row } from "react-bootstrap";

export default function Social() {
  return (
    <section id="about" className="about-mf sect-pt4 route">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="title-box text-center">
              <h3 className="title-a">Nuestras Redes</h3>
              <p className="subtitle-a">
                Encuentranos en redes sociales como @daisysunsetmusic.
              </p>
              <div className="line-mf"></div>
            </div>

            <div
              style={{
                marginBottom: "3rem",
                textAlign: "center",
                width: "100%",
              }}
            >
              <iframe
                className="instagram-media instagram-media-rendered"
                id="instagram-embed-0"
                src="https://www.instagram.com/daisysunsetmusic/embed/"
                allowtransparency="true"
                allowfullscreen="true"
                frameborder="0"
                height="565"
                data-instgrm-payload-id="instagram-media-payload-0"
                scrolling="no"
                style={{
                  background: "white",
                  maxWidth: 540,
                  width: "calc(100% - 2px)",
                  borderRadius: 3,
                  border: "1px solid rgb(219, 219, 219)",
                  boxShadow: "none",
                  display: "block",
                  margin: "0 auto",
                  minWidth: 326,
                  padding: 0,
                }}
                title="instagram"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
