import "./App.css";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Hero from "./components/hero";
import Merch from "./components/merch";
import Social from "./components/social";

function App() {
  return (
    <div className="App">
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <h1 className="logo">{/* <a href="#">DevFolio</a> */}</h1>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#hero">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  Redes
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#merch">
                  Merch
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contacto
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>

      <Hero />

      <main id="main">
        <Social />
        <Merch />
        <Contact />
        <Footer />
      </main>
    </div>
  );
}

export default App;
